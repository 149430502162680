import React, { useState, useMemo, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import { useIsMobile } from "../../hooks";
import loadable from "@loadable/component";
const VideoPlayer = loadable(() => import("../common/VideoPlayer"));
import { feedItems } from "../../static-data/LandingRefresh/milaFeedStatic";

const FeedItem = ({ video, videoMp4, thumbnail }) => {
  return (
    <div className="mila-feeds__item">
      <div className="mila-feeds__item-feed">
        <VideoPlayer
          videoSrc={video}
          videoSrcMp4={videoMp4}
          poster={thumbnail}
          className="mila-feeds__item-video"
          type="video/webm"
          isPosterOnly={true}
        />
      </div>
    </div>
  );
};

const NextArrow = ({ onClick }) => (
  <button type="button" className="slick-arrow slick-next" onClick={onClick}>
    <span className="screen-reader-text">Next</span>
    <svg className="icon icon-circle-right-arrow">
      <use xlinkHref="#icon-circle-right-arrow"></use>
    </svg>
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button type="button" className="slick-arrow slick-prev" onClick={onClick}>
    <span className="screen-reader-text">Previous</span>
    <svg className="icon icon-circle-left-arrow">
      <use xlinkHref="#icon-circle-left-arrow"></use>
    </svg>
  </button>
);

const MilaFeeds = () => {
  const isMobile = useIsMobile(769);
  const sliderRef = useRef(null);
  const [videoInit, setVideoInit] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const videoElements = document.querySelectorAll(".mila-feeds__item-video");

    const promises = Array.from(videoElements).map((video) => {
      return new Promise((resolve) => {
        if (video.readyState >= 3) {
          video.play();
          resolve();
        } else {
          video.onloadeddata = () => {
            video.play();
            resolve();
          };
        }
      });
    });

    Promise.all(promises).then(() => {
      setVideoInit(true);
    });
  }, []);

  const hoverHandler = () => {
    if (isMobile) return;
    sliderRef.current.slickPlay();
  };
  const hoverOutHandler = () => {
    if (isMobile) return;
    sliderRef.current.slickPause();
  };

  const sliderSettings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      touchThreshold: 10,
      variableWidth: true,
      centerMode: true,
      centerPadding: "60px",
      initialSlide: 0,
      autoplay: false,
      autoplaySpeed: 0,
      pauseOnHover: false,
      pauseOnFocus: false,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            variableWidth: true,
            slidesToShow: 1,
            centerMode: true,
            autoplay: false,
            centerPadding: "60px",
            autoplaySpeed: 4000,
            speed: 500,
            initialSlide: 1,
            arrows: true,
            cssEase: "ease",
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
          },
        },
      ],
    }),
    [videoInit]
  );

  return (
    <section className="mila-feeds" ref={ref}>
      <div className="container">
        <motion.div
          className="mila-feeds__header"
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
        >
          <h2 className="mila-feeds__title">
            Adored by thousands of Miliebers worldwide.
          </h2>
          <div className="mila-feeds__short-description">
            <p>
              Together, we've normalized having deep feelings for an air
              purifier.
            </p>
          </div>
        </motion.div>

        <motion.div
          className="mila-feeds__body"
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.2 }}
          onMouseEnter={hoverHandler}
          onMouseLeave={hoverOutHandler}
        >
          <Slider
            className="mila-feeds__slider"
            ref={sliderRef}
            {...sliderSettings}
          >
            {feedItems.map((item, index) => (
              <FeedItem
                video={item.video}
                videoMp4={item.videoMp4}
                thumbnail={item.thumbnail}
                key={index}
              />
            ))}
          </Slider>
        </motion.div>
      </div>
    </section>
  );
};

export default MilaFeeds;
